import React, { forwardRef, useEffect, useState } from "react";
import "./TaskDialog.css";
import { Task } from "../../entities/Task";
import { Group } from "../../entities/Group";
import Button from "../../elements/button/Button";
import useTranslate from "../../internl/translate";
import { useNavigate } from "react-router-dom";
import notify from "../../elements/toast/Toast";
import Dialog from "../../elements/dialog/Dialog";
import Section from "../../elements/section/Section";
import TextField from "../../elements/textField/TextField";
import { Done } from "../../entities/Done";
import { Score } from "../../entities/Score";
import tasksStorage from "../../dataLayer/tasksStorage";
import groupsStorage from "../../dataLayer/groupsStorage";
import { v4 as uuidv4 } from "uuid";
import { User } from "../../entities/User";
import userStorage from "../../dataLayer/userStorage";


interface TaskPageProps {
	task: Task;
	setTask: (task: Task | undefined) => void;
	doneTask: (onFinish: () => void, target?: Task | Done | Group | Score | string | undefined) => Promise<any>;
}

export default forwardRef(function TaskDialog(props: TaskPageProps, ref: React.ForwardedRef<HTMLDivElement>) {
	
	const [ user, setUser ] = useState<User>();
	const { task, setTask, doneTask } = props;
	const SAVE = useTranslate("save");
	const CREATE = useTranslate("create");
	let INTO_GROUP = useTranslate("into group");
	INTO_GROUP = INTO_GROUP.charAt(0).toUpperCase() + INTO_GROUP.slice(1);
	const TASK_EDIT = useTranslate("task edit");
	const TASK_NAME = useTranslate("task name");
	const ENTER_TASK_NAME = useTranslate("enter task name");
	const CREATE_NEW_GROUP = useTranslate("create new group");
	const navigate = useNavigate();
	const [ groupList, setGroupList ] = useState<Group[]>([]);
	const [ descriptionHeight, setDescriptionHeight ] = useState<number>(20);
	

	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser, "taskDialog");
	}, []);

	useEffect(() => {

		function calcHeight(value: string) {
			let numberOfLineBreaks = (value.match(/\n/g) || []).length;
			// min-height + lines x line-height + padding + border
			let newHeight = 30 + numberOfLineBreaks * 30 + 12 + 2;
			return newHeight;
		}

		const height = calcHeight(task.description || "");
		setDescriptionHeight(Math.min(height, 300));
	}, [ task.description ]);
	

	const getGroups = (group: Group) => {

		// console.log(group);

		setGroupList((tasks) => {
			const newGroup = [ ...tasks ];
			const index = newGroup.findIndex((t) => t.id === group.id);

			if (index >= 0) {
				if (group.active) {
					newGroup.splice(index, 1, group);
				} else {
					newGroup.splice(index, 1);
				}
			} else {
				newGroup.push(group);
			}

			return newGroup;
		});
	};


	useEffect(() => {
		groupsStorage.getGroups(getGroups);
	}, []);
	

	async function saveTask(onFinish: () => void, target: Task | undefined) {

		console.log("Save task", target);

		const task: Task | undefined = target;

		if (!task) {
			notify("Task is not valid");
			return;
		}

		// Set group ID to user ID = Personal
		if (!task?.group) {
			task.group = user?.id ?? "";
		}

		// TODO: remove this block later
		if (!task.author) {
			task.author = user?.email ?? "";
		}

		if (!task.active) {
			task.active = true;
		}

		task.lastDateModified = new Date().toISOString();

		// eslint-disable-next-line new-cap
		task.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		task.offset = getTimezoneOffset(new Date());

		if (task?.id) {
			await tasksStorage.saveTask(task);
			notify("Task saved");
		}

		onFinish();
	}
	
	
	const deleteTask = async (onFinish: () => void) => {

		if (!task?.id) {
			notify("Task not deleted!");
			onFinish();
			return;
		}

		tasksStorage.deleteTask(task);

		notify("Task deleted", 3);

		onFinish();

		navigate("/dashboard");
	};

	const openNewGroup = async () => {
		// navigate("/groups", { state: { openDialog: true } });
		navigate(`/groups/${uuidv4()}`);
	};

	async function closeDialog(onFinish: () => void) {
		setTask(undefined);
		navigate("/dashboard");
		onFinish();
	}

	function updateTask(attributeName: string, value: string | number | boolean) {
		console.dir(value);

		let localValue = value;

		if (attributeName === "interval") {
			try {
				localValue = Number(value);
			} catch (error) {
				console.error("Can't convert interval to number", error);
			}
		}

		const newTask: Task = {
			...tasksStorage.buildTask({ ...task }),
			[attributeName]: localValue,
		};
		
		setTask(newTask);
	}


	async function onClearReminder(onFinish: () => void) {
		// updateTask("reminderDate", "");
		// updateTask("reminderTime", "");
		// @ts-ignore
		setTask((task: Task) => {
			const newTask: Task = { ...task };
			newTask.reminderDate = "";
			newTask.reminderTime = "";
			return newTask;
		});
		onFinish();
	}
	
	
	return (
		<Dialog
			ref={ref}
			closeDialog={closeDialog}
			name={task?.id !== "" ? TASK_EDIT : "Create task"}
			tools={[
				<Button
					key={"create new group button"}
					text={CREATE_NEW_GROUP}
					onButtonClick={openNewGroup}
					styles={{ fontSize: 12 }}
				/>,
				<Button
					key={"save task button"}
					text={task?.id ? SAVE : CREATE}
					confirmButton
					type="submit"
					active={!(task?.name === "")}
					onButtonClick={saveTask}
					target={task}
				/>,
			]}
		>
			
			<form autoComplete="on">

				<Section>
					<TextField
						label={TASK_NAME}
						value={task?.name}
						onChange={(e) => updateTask("name", e.target.value)}
						placeHolder={ENTER_TASK_NAME}
					/>
				</Section>

				<Section>
					<div className="textField">
						<label htmlFor="taskDescription">
							<b>
								{ "Description:" }
							</b>
						</label>
						<textarea
							placeholder={"Some more details "}
							name="taskDescription"
							value={task?.description}
							onChange={(e) => updateTask("description", e.target.value)}
							className="description"
							style={{ height: descriptionHeight }}
						/>
					</div>
				</Section>
				
				<Section>
					<div className="textField">
						<label htmlFor="groupName">
							<b>
								{ INTO_GROUP }:
							</b>
						</label>
						<select
							placeholder="Select group name"
							name="groupName"
							value={task?.group}
							onChange={(e) => updateTask("group", e.target.value)}
						>
							{
								groupList.map((group: Group) => {
									return (
										<option value={group.id} key={group.id}>
											{group.name}
										</option>
									);
								})
							}
						</select>
					</div>
				</Section>

				<Section>
					<div className="textField">
						<label htmlFor="recurrency">
							<b>
							Recurring:
							</b>
						</label>
						<input
							type="checkbox"
							name="recurrency"
							checked={task?.recurring}
							onChange={(event) => updateTask("recurring", event.target.checked)}
						/>
					</div>
				</Section>

				{
					task?.recurring &&
					<Section>
						<div className="textField interval">
							<label htmlFor="recurring">
								<b>
									Once in:
								</b>
							</label>
							<input
								type="number"
								placeholder={task.interval?.toString() || "0"}
								name="recurring"
								// value={task.interval}
								onChange={(e) => updateTask("interval", e.target.value)}
							/>
							<b>
								days
							</b>
						</div>
					</Section>
				}

				<Section>
					<div className="textField">
						<label htmlFor="reminder">
							<b>
								Reminder:
							</b>
						</label>
						<div>
							<input
								type="date"
								placeholder={task?.reminderDate || "Date"}
								name="reminder"
								value={task?.reminderDate}
								onChange={(e) => updateTask("reminderDate", e.target.value)}
							/>
							<input
								type="time"
								placeholder={task?.reminderTime || "Time"}
								name="reminder"
								value={task?.reminderTime}
								onChange={(e) => updateTask("reminderTime", e.target.value)}
							/>
							<Button
								text={"Clear"}
								onButtonClick={onClearReminder}
							/>
						</div>
					</div>
				</Section>
			
			</form>

			<Section>
				<div>
					{
						task?.active &&
							<Button
								classes={"confirmButton"}
								text="Done"
								onButtonClick={doneTask}
								target={task}
								withConfirmationDialog
							/>
					}
					{
						task?.active &&
							<Button
								text={"Delete task"}
								onButtonClick={deleteTask}
								redButton
								target={task}
								withConfirmationDialog
								styles={{ width: 180 }}
							/>
					}
				</div>
			</Section>
			
		</Dialog>
	);
});

const getTimezoneOffset = (date: Date): string => {
	const dateString: string = date.toString();
	const index = dateString.indexOf("GMT");
	return dateString.substring(index+3, index+8);
};
