import { createContext, useContext } from "react";
import { User } from "../entities/User";


export interface AuthContextType {
	user: User | undefined;
	signOut: () => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

/**
 * Authentication context
 * @return {JSX.Element} App auth context element
 */
export function useAuthContext(): AuthContextType {
	return useContext(AuthContext);
}
