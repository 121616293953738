import { v4 as uuidv4 } from "uuid";
import { Score } from "../entities/Score";

const type = "scores";

let callbackFun: (score: Score) => void = () => {
	console.warn("Callback not set");
};

async function getScores(
	callback: (score: Score) => void,
) {
	callbackFun = callback;
	const scores: Score[] = JSON.parse(localStorage.getItem(type) || "[]");
	if (scores?.length > 0) {
		scores.forEach((item) => {
			callback(item);
		});
	}
}

async function saveScore(
	score: Score,
) {
	const scores: Score[] = JSON.parse(localStorage.getItem(type) || "[]");
	scores.push(score);
	localStorage.setItem(type, JSON.stringify(scores));
	callbackFun(score);
}

async function deleteScore(score: Score) {
	const scores: Score[] = JSON.parse(localStorage.getItem(type) || "[]");
	const filteredScores = scores.filter((item) => item.id !== score.id);
	localStorage.setItem(type, JSON.stringify(filteredScores));
	score.active = false;
	callbackFun(score);
}

function buildScore(...args: any) {
	const newScore: Score = {
		id: uuidv4(),
		active: true,
		authorEmail: "",
		points: 0,
		date: new Date().toISOString(),
		doneId: "",
		groupId: "",
		doneAuthorEmail: "",
		doneDate: "",
		taskName: "",
		...args,
	};

	return newScore;
}

const scoresStorage = {
	getScores,
	saveScore,
	buildScore,
	deleteScore,
};

export default scoresStorage;
