// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scoreItem {
    display: grid;
    border-radius: 8pt;
    align-content: center;
    margin: 4pt;
    grid-template-columns: 24fr 7fr 24fr 25fr 20fr;
    cursor: pointer;
}

.scoreItem .deleteButton, .saveButton {
    display: none;
}

.openItem .deleteButton, .openItem .saveButton {
    display: block;
    width: 90pt;
}

.showButton {
    display: block;
}

.doneName {
    grid-column: 1;
}

.scoreSeparator {
    grid-column: 2;
}

.doneAuthor {
    grid-column: 3;
}

.scoreDoneDate {
    grid-column: 4;
}

.scoreStars {
    grid-column: 5;
}

.deleteButton {
    grid-row: 2;
    grid-column: 5;
    background-color: red;
}

.saveButton {
    background-color: rgba(var(--color), 1);
    grid-row: 2;
    grid-column: 4;
}`, "",{"version":3,"sources":["webpack://./src/pages/scoring/ScoringPage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,8CAA8C;IAC9C,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,uCAAuC;IACvC,WAAW;IACX,cAAc;AAClB","sourcesContent":["\n.scoreItem {\n    display: grid;\n    border-radius: 8pt;\n    align-content: center;\n    margin: 4pt;\n    grid-template-columns: 24fr 7fr 24fr 25fr 20fr;\n    cursor: pointer;\n}\n\n.scoreItem .deleteButton, .saveButton {\n    display: none;\n}\n\n.openItem .deleteButton, .openItem .saveButton {\n    display: block;\n    width: 90pt;\n}\n\n.showButton {\n    display: block;\n}\n\n.doneName {\n    grid-column: 1;\n}\n\n.scoreSeparator {\n    grid-column: 2;\n}\n\n.doneAuthor {\n    grid-column: 3;\n}\n\n.scoreDoneDate {\n    grid-column: 4;\n}\n\n.scoreStars {\n    grid-column: 5;\n}\n\n.deleteButton {\n    grid-row: 2;\n    grid-column: 5;\n    background-color: red;\n}\n\n.saveButton {\n    background-color: rgba(var(--color), 1);\n    grid-row: 2;\n    grid-column: 4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
