import notify from "../elements/toast/Toast";
import { Done } from "../entities/Done";
import { Group } from "../entities/Group";
import { Invitation } from "../entities/Invitation";
import { Message } from "../entities/Message";
import { MessageToken } from "../entities/MessageToken";
import { Task } from "../entities/Task";
import { User } from "../entities/User";
import donesStorage from "./donesStorage";
import groupsStorage from "./groupsStorage";
import invitedGroupsStorage from "./invitedGroupsStorage";
import tasksStorage from "./tasksStorage";
import userStorage from "./userStorage";


const callerName = "backend";

let socket: WebSocket;

function init(
	user: User,
): () => void {

	console.log("init", user);

	const domain: string = window.location.hostname;
	let socketURL: string = `ws://${domain}/socket`;
	console.log(window.location.protocol);
	if (window.location.protocol === "https:") {
		socketURL = `wss://${domain}/socket`;
	}
	const webSocket: WebSocket = new WebSocket(socketURL);
	// const webSocket: WebSocket = new WebSocket("ws://ciupa.local:8080");

	webSocket.addEventListener("error", (event: Event) => {
		notify("Error in connection to server", 2);
		console.log("WebSocket error: ", event);
	});

	webSocket.addEventListener("open", (event) => {
		console.log("Socket opened", event);
		notify("Connected to server", 3);

		if (!webSocket) return;

		socket = webSocket;

		userStorage.getUser(sendUser, callerName);

		webSocket.addEventListener("message", (event) => {
			// console.log("Message from server ", event.data);

			try {
				const data = JSON.parse(event.data);

				switch (data.type) {
				case "task":
					const task = data as Task;
					tasksStorage.saveTask(task);
					break;
				case "group":
					const group = data as Group;
					if (group.participants.includes(user.email)) {
						groupsStorage.saveGroup(group);
					}
					if (group.invited?.includes(user.email)) {
						invitedGroupsStorage.saveGroup(group);
					}
					break;
				case "done":
					const done = data as Done;
					donesStorage.saveDone(done);
					break;
				case "authenticated":
					sync(user);
					break;
				default:
					break;
				}
			} catch (e) {
				console.error(e);
			}
		});
	});

	const closeSocket = () => {
		webSocket.close();
	};

	return closeSocket;
}

function sync(user: User) {
	console.log("sync");
	sendMessage({ type: "getTasks" });
	sendMessage({ type: "getDones" });
	sendMessage({ type: "getGroups" });
	sendMessage({ type: "saveUser", data: user });
	sendMessage({ type: "getInvitedGroups" });
	tasksStorage.getTasks(sendTask, callerName);
	groupsStorage.getGroups(sendGroup, callerName);
	donesStorage.getDones({ callback: sendDone, caller: callerName });
}

function sendTask(task: Task) {
	// console.log("Send task: ", task);
	sendMessage({ type: "saveTask", data: task });
}

function sendUser(user: User | undefined) {
	if (!user) {
		console.warn("User is: ", user);
		return;
	}
	// console.log("Send user", user);
	sendMessage({ type: "auth", data: user });
}

function sendGroup(group: Group) {

	if (group.name !== "personal") {
		// console.log("Sending group: ", group);
		sendMessage({ type: "saveGroup", data: group });
	}
}

function sendDone(done: Done | null) {
	// console.log("Sending done: ", done);
	if (done) {
		sendMessage({ type: "saveDone", data: done });
	}
}

function sendMToken(token: MessageToken) {
	console.log("Send token: ", token);
	sendMessage({ type: "saveToken", data: token });
}

function sendMessage(message: Message) {
	socket.send(JSON.stringify(message));
}

async function inviteUser(email: string, groupId: string): Promise<boolean> {
	const invitation: Invitation = {
		email,
		groupId,
	};
	sendMessage({ type: "inviteUser", data: invitation });
	return true;
}

const serverSync = {
	init,
	sendTask,
	sendMToken,
	inviteUser,
};

export default serverSync;
