import { v4 as uuidv4 } from "uuid";
import { Group } from "../entities/Group";
import objects from "../utils/objects";

const type = "groups";

const callBackCallers: Map<string, Function> = new Map();

function callAll(group: Group) {
	callBackCallers.forEach((callBack) => {
		callBack(group);
	});
}

function createPersonalGroup(groups: Group[]): Group[] {
	const personalGroup: Group = buildGroup({
		name: "personal",
		active: true,
	});
	
	const newGroups = [ ...[ personalGroup ], ...groups ];

	return newGroups;
}

async function getGroups(
	callback: (group: Group) => void,
	caller?: string,
) {
	if (caller) callBackCallers.set(caller, callback);
	
	let groups: Group[] = JSON.parse(localStorage.getItem(type) || "[]");

	if (!groups.length || groups.length === 0) {
		groups = createPersonalGroup(groups);
		localStorage.setItem(type, JSON.stringify(groups));
	}

	const personalGroup = groups.find((gr: Group) => gr.name === "personal");

	if (!personalGroup) {
		groups = createPersonalGroup(groups);
		localStorage.setItem(type, JSON.stringify(groups));
	} else if (!personalGroup.active) {
		personalGroup.active = true;
		localStorage.setItem(type, JSON.stringify(groups));
	}

	// console.log(groups);
	
	groups.forEach((item) => {
		callback(item);
	});
}

async function saveGroup(
	group: Group,
) {

	if (group.name === "personal") return;

	let groups: Group[] = JSON.parse(localStorage.getItem(type) || "[]");

	let echo = false;

	const oldGroup = groups.find((g) => g.id === group.id);

	if (oldGroup) {
		// console.log(objects.deepEqual(oldGroup, group));
		if (!objects.deepEqual(oldGroup, group)) {
			groups = groups.map((t) => {
				return t.id === group.id ? group : t;
			});
		} else {
			echo = true;
		}
	} else {
		groups.push(group);
	}

	localStorage.setItem(type, JSON.stringify(groups));

	if (!echo) {
		callAll(group);
	}
}

async function deleteGroup(group: Group) {
	const tasks: Group[] = JSON.parse(localStorage.getItem(type) || "[]");
	const filteredTasks = tasks.filter((item) => item.id !== group.id);
	localStorage.setItem(type, JSON.stringify(filteredTasks));
	group.active = false;
	callAll(group);
}

function buildGroup({
	...args
}) {
	const newGroup: Group = {
		type: "group",
		id: uuidv4(),
		name: "",
		active: false,
		participants: [],
		invited: [],
		...args,
	};

	return newGroup;
}

function getGroup(groupId: string): Group | undefined {
	const groups: Group[] = JSON.parse(localStorage.getItem(type) || "[]");
	const group: Group | undefined = groups.find((item) => item.id === groupId);
	return group;
}

const groupsStorage = {
	getGroups,
	saveGroup,
	buildGroup,
	deleteGroup,
	getGroup,
};

export default groupsStorage;
