import React, { useRef } from "react";
import { Score } from "../../entities/Score";
import Button from "../../elements/button/Button";
import { Firestore, deleteDoc, doc, getFirestore } from "firebase/firestore";
import notify from "../../elements/toast/Toast";
import "./ScoredDoneRow.css";

interface ScoredDoneRowProps {
	score: Score;
}

export default function ScoredDoneRow(props: ScoredDoneRowProps) {

	const { score } = props;
	const scoreRef = useRef<HTMLDivElement>(null);
	const db: Firestore = getFirestore();

	const closeScoreItem = (ScoreItem: HTMLElement | null) => {
		ScoreItem?.classList.toggle("openScoreItem");
	};

	const openScoreItem = (ScoreItem: HTMLElement | null) => {
		ScoreItem?.classList.toggle("openScoreItem");
	};

	const deleteScore = async (onFinish: any, target?: any) => {

		console.log("Delete score ", target);

		const score: Score = target as Score;

		if (score) {
			await deleteDoc(doc(db, "scores", score.id));
			
			notify("Score deleted");
			onFinish();
		} else {
			console.error("Score is: ", score);
		}
	};

	return (
		<div
			className={"scoredDoneItem"}
			key={score.id}
			ref={scoreRef}
		>
                  
			<header>
				<div
					className={"close"}
					title="Close"
					onClick={() => closeScoreItem(scoreRef.current)}
				>
					<i className="material-icons">clear</i>
				</div>
			</header>

			<div
				className="scoredDoneRow"
				onClick={() => openScoreItem(scoreRef.current)}
			>
				<div>
					<p>
						{
							`${score.points} points`
						}
					</p>
				</div>

				<div
					className={"scoredDoneSeparator"}
				>
					<p>
            for
					</p>
				</div>

				<div
					className={"scoredDoneName"}
				>
					<p>
						{score.taskName}
					</p>
				</div>

				<div
					className={"scoredDoneSeparator"}
				>
					<p>
            done on
					</p>
				</div>

				<div
					className={"scoredDoneDate"}
				>
					<p>
						{
							score.date
						}
					</p>
				</div>

				<div className="actionRow">
					<Button
						text="Delete"
						classes="deleteButton"
						target={score}
						onButtonClick={deleteScore}
					/>
				</div>
			</div>
		</div>
	);
}
