import React, { useEffect, useRef, useState } from "react";
import AverageScore from "../../elements/averageScore/AverageScore";
import Button from "../../elements/button/Button";
import { Done } from "../../entities/Done";
import notify from "../../elements/toast/Toast";
import "./DonesList.css";
import donesStorage from "../../dataLayer/donesStorage";

interface DoneListProps {
	preview?: boolean;
	onRefresh?: () => void;
}

export default function DonesList(props: DoneListProps) {

	const { preview } = props;
	const [ dones, setDones ] = useState<Done[]>([]);
	const doneRefs = useRef<HTMLDivElement[] | null[]>([]);


	const getDone = (done: Done | null) => {

		if (!done) return;

		setDones((dones) => {
			const newDones = [ ...dones ];
			const index = newDones.findIndex((t) => t.id === done.id);

			if (index >= 0) {
				if (done.active) {
					newDones.splice(index, 1, done);
				} else {
					newDones.splice(index, 1);
				}
			} else if (done.active) {
				newDones.push(done);
			}

			newDones.sort((a: Done, b: Done) => {
				if (!a.date || !b.date) return 1;
				const aDate = new Date(a.date);
				const bDate = new Date(b.date);
				return aDate < bDate ? 1 : -1;
			});
			
			return newDones;
		});
	};

	useEffect(() => {
		donesStorage.getDones({
			callback: getDone,
			caller: "donesmodule",
			limit: preview ? 10 : undefined,
		});
	}, []);

	const openDoneItem = (doneItem: HTMLElement | null) => {

		console.dir(doneItem);

		// close all other items from list
		doneRefs.current.forEach((item) => {
			if (item?.classList.contains("openDoneItem")) {
				item.classList.remove("openDoneItem");
			}
		});
		
		doneItem?.classList.toggle("openDoneItem");
	};

	const closeDoneItem = (doneItem: HTMLElement | null) => {
		doneItem?.classList.toggle("openDoneItem");
	};
	
	const deleteDoneTask = async (onFinish: any, target: any): Promise<void> => {

		console.log("Delete done ", target);

		const done: Done = target as Done;

		if (done) {
			donesStorage.deleteDone(done);
			notify("Done deleted", 3);
			onFinish();
		} else {
			onFinish();
		}
	};
	

	return (
		<>
			{
				dones.length > 0 ?
					<div id={"doneList"}>
						{
							dones.map((done: Done, index: number) => {
								const dateTime = new Date(done.date || "");
								const dateTimeString = dateTime.toLocaleDateString().concat(" ", dateTime.toLocaleTimeString());

								return (
									<div
										className={"doneItem"}
										key={done.id}
										ref={(el) => doneRefs.current[index] = el}
									>
                  
										<header>
											<div
											// style={styles.closeButton}
												className={"close"}
												title="Close"
												onClick={() => closeDoneItem(doneRefs.current[index])}
											>
												<i className="material-icons">clear</i>
											</div>
										</header>

										<div
											className="doneRow"
											onClick={() => openDoneItem(doneRefs.current[index])}
										>
											<div className={"doneName"}>
												<p>
													{done.taskName}
												</p>
											</div>

											<div className={"doneGroup"}>
												<p>
													{`in ${done.groupName} group`}
												</p>
											</div>

											<div className={"doneDate"}>
												<p>
													{dateTimeString}
												</p>
											</div>

											<div className={"doneScore"}>
												<AverageScore
													doneId={done.id || ""}
												/>
											</div>

											<div className="actionRow">
												<Button
													text="Delete"
													classes="deleteButton"
													target={done}
													onButtonClick={deleteDoneTask}
												/>
											</div>
										</div>
									</div>
								);
							})
						}
					</div>					:
					<div>
						No tasks
					</div>
			}
		</>
	);
}
