import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";


/**
 * Render app root element
 * @return {JSX.Element} App root directory
 */
export default function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element {

	const auth = useAuthContext();
	const location = useLocation();

	// console.log(auth.user);

	if (!auth.user) {
		return (
			<Navigate
				to="/login"
				state={{ from: location }} replace
			/>
		);
	}

	return children;
}
