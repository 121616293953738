import React, { useEffect, useState } from "react";
import { Group } from "../../entities/Group";
import { Done } from "../../entities/Done";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./ScoringPage.css";
import Button from "../../elements/button/Button";
import { AuthContextType, useAuthContext } from "../../context/AuthContext";
import { Score } from "../../entities/Score";
import ScoreSelector from "../../elements/score/ScoreSelector";
import ScoreList from "../../modules/scoreList/ScoreList";
import donesStorage from "../../dataLayer/donesStorage";
import scoresStorage from "../../dataLayer/scoreStorage";

interface UsersMap {
	[key: string]: string;
}

interface ScoringProps {
	// eslint-disable-next-line no-unused-vars
	setPageTools: (elements: JSX.Element[]) => void;
	// eslint-disable-next-line no-unused-vars
	setPageName: (element: JSX.Element) => void;
	groupsList: Group[];
}

export default function ScoringPage(props: ScoringProps) {
	
	const auth: AuthContextType = useAuthContext();
	const { setPageName, setPageTools, groupsList } = props;
	const [ doneMap, setDoneMap ] = useState<Map<string, Done[]>>(new Map());
	const [ groupsMap, setGroupsMap ] = useState<Map<string, Group>>(new Map());
	const [ users, setUsers ] = useState<UsersMap>({});
	const [ openItemId, setOpenItemId ] = useState<string>("");
	const [ selectedScore, setSelectedScore ] = useState<number>(0);

	
	useEffect(() => {
		
		setPageName(
			<h3 className={"pageName"}>
				Scoring
			</h3>,
		);
		
		return () => {
			setPageName(<div/>);
		};
	}, [ setPageName ]);

	useEffect(() => {

		setPageTools([ <div key="123"/> ]);

		return () => {
			setPageTools([ <div key="123"/> ]);
		};
	
	}, [ setPageTools ]);

	useEffect(() => {

		async function getGroups() {

			const newGroupsMap: Map<string, Group> = new Map();

			for (const group of groupsList) {
				newGroupsMap.set(group.id, group);
			}

			setGroupsMap(newGroupsMap);
		}

		getGroups();
	
	}, [ groupsList ]);

	useEffect(() => {
		getDones();
	}, []);

	useEffect(() => {

		const getNicknames = (emails: string[]) => {

			console.debug(emails);

			const functions = getFunctions();
			const getUserNicknames = httpsCallable(functions, "getUserNicknames");

			if (getUserNicknames) {
				getUserNicknames({
					emails: emails,
				}).then((result) => {
					setUsers(result.data as UsersMap);
				}).catch((error) => {
					// Getting the Error details.
					const code = error.code;
					console.error(code + error.message);
					console.error(error.details);
				});
			
			}
		};

		const emails: string[] = [];

		groupsList.forEach((group: Group) => {
			group.participants?.forEach((email: string) => {
				if (!emails.includes(email)) {
					emails.push(email);
				}
			});
		});

		if (emails.length > 0) {
			getNicknames(emails);
		}

	}, []);


	function getDones() {
		
		if (!auth.user) {
			return;
		}

		const newDonesMap: Map<string, Done[]> = new Map();

		donesStorage.getDonesForScoring().forEach((done: Done) => {

			const group: Group | undefined = groupsMap.get(done.group);

			if (group) {
				done.groupName = group.name;
			}

			if (newDonesMap.has(done.group)) {
				newDonesMap.get(done.group)?.push(done);
			} else {
				newDonesMap.set(done.group, [ done ]);
			}

			newDonesMap.get(done.group)?.sort((a: Done, b: Done) => {
				if (!a.date || !b.date) return 1;
				return a.date < b.date ? 1 : -1;
			});

		});

		setDoneMap(newDonesMap);
	}


	function onStarClick(done: Done, score: number) {
		console.debug(done.id, score);
		setOpenItemId(done.id ?? "");
		setSelectedScore(score);
	}

	function onItemClick(itemId: string) {
		console.debug(itemId);
		setSelectedScore(0);
		setOpenItemId(itemId);
	}

	async function scoreDone(onFinish: any, target?: any) {

		const done: Done = target as Done;
		if (!done?.id) {
			setTimeout(onFinish(), 1000);
			return;
		}

		const score: Score = scoresStorage.buildScore({
			date: new Date().toISOString(),
			authorEmail: auth.user?.email || "",
			points: selectedScore,
			doneId: done.id,
			groupId: done.group,
			doneAuthorEmail: done.author,
			doneDate: done.date,
			taskName: done.taskName || "",
		});

		await scoresStorage.saveScore(score);

		const doneArbiters = done.arbiters?.filter((email) => email !== auth.user?.email);

		donesStorage.updateDone(done.id, {
			arbiters: doneArbiters,
		});

		onFinish();
	}

	async function deleteDone(onFinish: any, target?: any) {

		const done: Done = target as Done;

		if (!done?.id) {
			setTimeout(onFinish(), 1000);
			return;
		}

		const functions = getFunctions();
		const deleteScoreRequest = httpsCallable(functions, "deleteScoreRequest");

		if (deleteScoreRequest) {
			deleteScoreRequest({
				id: done.id,
			}).then((result) => {
				console.debug(result.data);
			}).catch((error) => {
				// Getting the Error details.
				const code = error.code;
				console.error(code + error.message);
				console.error(error.details);
			}).finally(() => {
				onFinish();
			});
		}
	}
	
	
	return (
		<div className={"page"}>

			<h3>
				To add score:
			</h3>
			
			<div>
				{ doneMap.size !== 0 ?
					Array.from(doneMap.keys()).map((groupId: string) =>
						<div className="groupRow" key={groupId}>
							<header>
								<p>
									{groupsMap.get(groupId)?.name}
								</p>
							</header>

							<div className="donesContainer">
								{
									doneMap.get(groupId)?.map((done: Done) =>
										<div
											className={openItemId == done.id ? "scoreItem openItem" : "scoreItem"}
											key={done.id}
										>
											<div
												className={"doneName"}
												onClick={() => onItemClick(done.id ?? "")}
											>
												<p>
													{done.taskName}
												</p>
											</div>
											<div
												className={"scoreSeparator"}
												onClick={() => onItemClick(done.id ?? "")}
											>
												<p>
													done by
												</p>
											</div>
											<div
												className={"doneAuthor"}
												onClick={() => onItemClick(done.id ?? "")}
											>
												<p>
													{users?.[done.author] ?? done.author}
												</p>
											</div>
											<div
												className={"scoreDoneDate"}
												onClick={() => onItemClick(done.id ?? "")}
											>
												<p>
													{
														done.date
													}
												</p>
											</div>

											<ScoreSelector
												classes="scoreStars"
												onClick={onStarClick}
												target={done}
												selectedScore={openItemId == done.id ? selectedScore : 0}
											/>

											{
												selectedScore > 0 &&
												<Button
													classes={"saveButton"}
													text="Save"
													onButtonClick={scoreDone}
													target={done}
												/>
											}
											
											<Button
												classes="deleteButton"
												text="Delete"
												onButtonClick={deleteDone}
												target={done}
											/>
											
										</div>,
									)
								}
							</div>
						</div>,
					)	:
					<p>
						Nothing done yet
					</p>
				}
			</div>
			
			<h3>
				Scored:
			</h3>
			<ScoreList />

		</div>
	);
}
